import React from 'react'
import { graphql } from 'gatsby'
import { Container, Inner} from '../components/responsive/'
import H1 from '../components/h1'
import Content from '../components/content'
import Header from '../components/header/header'
import ContainerFooter from '../components/containerFooter'
import Footer from '../components/footer/footer'
import Wrap from '../components/wrap'
import Metadata from '../components/metadata'
import './index.css'

export default ({ data }) => (
      <div>
      <Metadata title='ボランティア募集'/>
      <Wrap>
      <Header active='volunteer'/>
      <Container backgroundColor='#FFF' paddingTop='1em' marginTop='4.5em'>
      <H1 fluid={data.volunteer.childImageSharp.fluid} >
      ボランティア募集
      </H1>
      <Inner marginTop='2em'>
        <Content>
        Drupal Camp DENでは当日の会場の運営を手伝っていただけるボランティアを募集いたしております｡<br/>
Drupal 興味がある方、共感していただける方。またDrupalって何？という方ももちろんサポートを歓迎しております。<br/>
年齢性別不問、未経験者の方も大歓迎ですので、ぜひご連絡ください。<br/>
        </Content>
        <Content>
<Inner as='span' bold>日時</Inner>　2018年11月17日（土曜日）<br/>
<Inner as='span' bold>場所</Inner>　大田区産業プラザPio 2F biz BEACH CoWorking 　<br/>
        </Content>
        <Content>
・会場受付<br/>
・備品準備<br/>
・会場撮影<br/>
など。
        </Content>
        <Content>
申し込み・問い合わせ先<br/>
DEN事務局・楠目（クスメ）まで(Email:mkusume@gmail.com)
        </Content>
      </Inner>
      <ContainerFooter/>
      </Container>
      <Footer/>
      </Wrap>
      </div>
)

export const query = graphql`
query {
  volunteer:file(relativePath: { eq: "ボランティア募集.png" }) {
    childImageSharp {
      fluid(maxWidth: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
}
`
